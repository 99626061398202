.navbar {
  position: relative;
  width: 100%;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: space-around;
  // background: var(--sec-color);
  animation: navLoad 500ms ease-in;
  /* 
  :hover {
    box-shadow: 4px 15px 18px 1px rgba(0, 0, 0, 0.3);
    border: solid var(--medGrey) 2px;
    border-radius: 5px;
  } */

  @keyframes navLoad {
    0% {
      transform: translateY(-100%);
    }
    60% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(0);
    }
  }

  // .BrandLogo {
  //   width: 100%;
  // }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    // padding: 0.5em;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;

    /* h2 {
    cursor: pointer;
    color: var(--main-color);
  } */

    .menuIcon {
      color: var(--black);
      font-weight: 700;
      font-size: var(--fontSuperBig);
      cursor: pointer;
      display: none;
    }

    @media screen and (max-width: 900px) {
      .menuIcon {
        display: flex;

        // position: absolute;
        z-index: 300;
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
    .NavBars {
      display: flex;
      flex-direction: row;
      text-align: center;
      justify-content: space-around;
      /* align-items: center; */
      cursor: pointer;
      animation: navLinkLoad 500ms ease-in 400ms;
      animation-fill-mode: forwards;
      transform: scale(0);

      @keyframes navLinkLoad {
        0% {
          transform: scale(0);
        }
        90% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      .navItems {
        font-size: 1rem;
        font-weight: 900;
        margin-bottom: 0;
        color: var(--sec-color);

        &:hover {
          color: var(--black);
          box-shadow: 0px 24px 48px rgba(27, 44, 193, 0.1);
        }
      }

      .closeMenu {
        font-weight: 700;
        font-size: var(--fontBig);
        cursor: pointer;
        display: none;
      }

      a {
        display: flex;
        align-items: center;
      }

      .NavActive {
        background-color: var(--main-color);
        padding: 0.5em 1em;
        border-radius: 10px;
        color: var(--white);
        .navItems {
          color: var(--sec-color);
        }
        // h3 {
        //   color: var(--white);
        // }

        @media screen and (max-width: 900px) {
          background-color: var(--sec-color);
          padding: 1em 2em;
          .navItems {
            color: var(--main-color);
          }
        }
      }

      @media screen and (max-width: 900px) {
        position: fixed;
        text-align: left;
        width: 100%;
        top: 0;
        /* top: -100%; */
        left: 0;
        /* right: 0; */
        flex-direction: column;
        // background: var(--main-color);
        background: rgba(211, 211, 211, 0.25);

        backdrop-filter: blur(9px);
        -webkit-backdrop-filter: blur(9px);
        -moz-backdrop-filter: blur(9px);

        /* border: solid 1px red; */
        padding: 1.3em;
        transition: 350ms;
        z-index: 200;
        /* padding-bottom: 5em; */

        .navItems {
          font-size: 1.15rem;
          // font-weight: 400;
          margin: 1em 0;
          color: var(--sec-color);

          // &:hover {
          //   background: #fff;
          //   padding: 1em;
          //   color: var(--main-color);
          //   width: 100%;
          // }
        }

        /* .mobileNavhead {

    } */
      }

      /* .navItems {
    background: #000;
  } */
    }

    .navOut {
      top: -900px;
    }

    .closeMenu {
      display: block;
      color: var(--black);
    }
  }
}
