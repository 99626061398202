.spinCon {
  margin: 2em auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/webp/Story024.webp") center center no-repeat;
  background-blend-mode: soft-light;
  background-size: cover;
  background-attachment: fixed;
  // opacity: 0.5;
  // background-color: red;
  .spinit {
    // border: 5px solid #424141;
    // border-top: 5px solid #a4a4a4;
    // border-radius: 50%;
    // width: 50px;
    // height: 50px;
    margin: 2rem auto;
    animation: scales 1s linear infinite;

    @keyframes scales {
      0% {
        transform: scale(0);
      }

      50% {
        transform: scale(1);
      }

      100% {
        transform: scale(0);
      }
    }
  }
}
