footer {
  width: 100%;
  display: flex;
  background: var(--main-color);
  color: var(--white);
  align-items: center;

  /* 
  :hover {
    box-shadow: 4px 15px 18px 1px rgba(0, 0, 0, 0.3);
    border: solid var(--medGrey) 2px;
    border-radius: 5px;
  } */
}
