.venue {
  .hero {
    // background: var(--sec-color) url("../../assets/aroun-tiffany6.jpeg") top
    //   center no-repeat;
    // background-blend-mode: soft-light;
    // background-size: cover;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-attachment: fixed;
    // height: 100vh;
    height: 90vh;
    // overflow: hidden;

    // margin: 0 auto;
    background: url("../../assets/webp/Story020.webp") top center no-repeat;
    background-blend-mode: soft-light;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      background-attachment: scroll;
    }

    .title-container {
      //   padding: 6em 0em;
      display: grid;
      place-items: center;
      background: rgba(211, 211, 211, 0.25);

      // backdrop-filter: blur(3px);

      height: inherit;

      svg {
        filter: drop-shadow(4px 2px 1px #000000);
        text {
          color: var(--sec-color);
          font-size: 9rem;
          stroke: var(--sec-color);
          stroke-width: 3;
          animation: textAnimate 5s infinite alternate;

          @media screen and (max-width: 900px) {
            font-size: 5rem;
            stroke-width: 2;
          }
          @media screen and (max-width: 500px) {
            font-size: 2rem;
            stroke-width: 1;
          }

          @keyframes textAnimate {
            0% {
              stroke-dasharray: 0 50%;
              stroke-dashoffset: 20%;
              fill: var(--main-color);
            }

            100% {
              stroke-dasharray: 50% 0;
              stroke-dashoffstet: -20%;
              fill: hsla(189, 68%, 75%, 0%);
            }
          }
        }
      }
    }
  }
  .tex {
    height: 90vh;

    background: url("../../assets/webp/Story022.webp") center center no-repeat;
    background-blend-mode: soft-light;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      background-attachment: scroll;
    }

    .title-container {
      //   padding: 6em 0em;
      display: grid;
      place-items: center;
      background: rgba(211, 211, 211, 0.25);

      // backdrop-filter: blur(3px);

      height: inherit;

      svg {
        filter: drop-shadow(4px 2px 1px #000000);

        text {
          color: var(--sec-color);
          font-size: 9rem;
          stroke: var(--sec-color);
          stroke-width: 3;
          animation: textAnimate 5s infinite alternate;
          @media screen and (max-width: 900px) {
            font-size: 5rem;
            stroke-width: 2;
          }
          @media screen and (max-width: 500px) {
            font-size: 3.5rem;
            stroke-width: 1;
          }

          @keyframes textAnimate {
            0% {
              stroke-dasharray: 0 50%;
              stroke-dashoffset: 20%;
              fill: var(--main-color);
            }

            100% {
              stroke-dasharray: 50% 0;
              stroke-dashoffstet: -20%;
              fill: hsla(189, 68%, 75%, 0%);
            }
          }
        }
      }
    }
  }
  .warm {
    height: 90vh;

    background: url("../../assets/webp/Story021.webp") center center no-repeat;
    background-blend-mode: soft-light;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      background-attachment: scroll;
    }

    .title-container {
      //   padding: 6em 0em;
      display: grid;
      place-items: center;
      background: rgba(211, 211, 211, 0.25);

      // backdrop-filter: blur(3px);

      height: inherit;

      svg {
        filter: drop-shadow(4px 2px 1px #000000);

        text {
          color: var(--sec-color);
          font-size: 9rem;
          stroke: var(--sec-color);
          stroke-width: 3;
          animation: textAnimate 5s infinite alternate;

          @media screen and (max-width: 900px) {
            font-size: 5rem;
            stroke-width: 2;
          }
          @media screen and (max-width: 500px) {
            font-size: 4rem;
            stroke-width: 1;
          }

          @keyframes textAnimate {
            0% {
              stroke-dasharray: 0 50%;
              stroke-dashoffset: 20%;
              fill: var(--main-color);
            }

            100% {
              stroke-dasharray: 50% 0;
              stroke-dashoffstet: -20%;
              fill: hsla(189, 68%, 75%, 0%);
            }
          }
        }
      }
    }
  }

  p {
    font-style: italic;
    font-size: 1rem;
  }
  .warm-hotel,
  .hotel {
    padding: 5em 0em;

    background: var(--sec-color);
    // height: 100vh;
    // display: grid;
    // place-items: center;
  }

  .festives {
    border: solid 2px var(--main-color);
    border-radius: 6px;
    padding: 1em;
    background-color: #fff;
    text-align: start;

    @media screen and (max-width: 700px) {
      margin-bottom: 1em;
    }

    h6 {
      padding: 0.5em;
      .icon {
        color: lightgray;
        margin-right: 0.3em;
      }
    }
    h3 {
      // background: var(--main-color);
      // border-radius: 6px;
      padding: 0.5em 0;
      text-align: center;

      // color: #fff;
      border-bottom: solid 2px lightgray;
    }
  }
  // .warm-hotel {
  //   padding: 5em 0em;
  // }
}
