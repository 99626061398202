.our-story {
  .hero {
    // height: 100vh;
    overflow: hidden;
    position: relative;
    // margin: 0 auto;
    background: url("../../assets/webp/2togetheras2.webp") center center
      no-repeat;
    // background-blend-mode: soft-light;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      background-attachment: scroll;
    }

    @media screen and (max-width: 900px) {
      height: 100vh;
    }
    @media screen and (max-width: 320px) {
      height: max-content;
    }

    padding: 7em 2em;
    h2 {
      font-size: 7rem;
      line-height: 90px;
      letter-spacing: 10px;
      color: var(--sec-color);
    }

    h3 {
      position: relative;
      width: 100%;
      margin: 0 auto;
      color: var(--white);

      &::before {
        content: " ";
        display: flex;
        height: 2px;
        width: 40%;
        position: absolute;
        top: 50%;
        left: 0;
        border: solid 1px white;
      }
      &::after {
        content: " ";
        display: flex;
        height: 2px;
        width: 40%;
        position: absolute;
        top: 50%;
        right: 0;
        border: solid 1px white;
      }
      @media screen and (max-width: 900px) {
        &::before {
          width: 30%;
        }
        &::after {
          width: 30%;
        }
      }

      @media screen and (max-width: 600px) {
        &::before {
          width: 15%;
        }
        &::after {
          width: 15%;
        }
      }
    }

    @media screen and (max-width: 500px) {
      h2 {
        font-size: 5rem;
        line-height: 70px;
      }
    }
  }

  .home-containers {
    // margin: 100px auto;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    // img {
    //   // object-fit: cover;
    //   // object-position: center;
    //   z-index: 5;
    // }

    // .slick-next:before,
    // .slick-prev:before {
    //   // display: none;
    // }
    @media screen and (max-width: 600px) {
      height: auto;
    }
  }
  .slick-prev,
  .slick-next {
    top: 20%;
    z-index: 1000;
    color: var(--sec-color);
    @media screen and (max-width: 900px) {
      top: 40%;
    }
    // background-color: red;
  }

  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }

  .story-time {
    // background: var(--medGrey) url("../../assets/aroun-tiffany8.jpeg") center
    //   center no-repeat;
    background-blend-mode: color;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    background-color: var(--main-color);

    .story {
      // background-color: var(--white);
      padding: 5em 0em;
      position: relative;

      background: url("../../assets/webp/Story030.webp") top center no-repeat;
      // background-blend-mode: soft-light;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-attachment: fixed;
      // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        background-attachment: scroll;
      }
    }

    .storyInfo,
    .story {
      padding: 2em 2.2em;
      @media screen and (max-width: 900px) {
        padding: 1em;
      }
    }

    h2 {
      color: var(--sec-color);
      font-size: 4rem;
    }

    p {
      color: var(--sec-color);
      font-size: var(--fontBig);
    }
  }

  .gallery {
    padding: 5em;

    .react-photo-album--photo {
      object-fit: contain;
    }
    @media screen and (max-width: 900px) {
      padding: 1em;
    }
  }

  .best {
    padding: 5em 0em;
    position: relative;

    background: url("../../assets/webp/2togetheras7.webp") top center no-repeat;
    // background-blend-mode: soft-light;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      background-attachment: scroll;
    }
    // color: var(--white);
    // div {
    //   // margin: 2em auto;
    //   height: 300px;
    // }
    h2 {
      color: var(--sec-color);
      font-size: 4rem;
    }
  }

  .propose {
    // background-color: var(--white);
    padding: 5em 0em;
    position: relative;
    height: 50vh;

    background: url("../../assets/webp/Story014.webp") top center no-repeat;
    // background-blend-mode: soft-light;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      background-attachment: scroll;
    }
    h2 {
      color: var(--sec-color);
      font-size: 4rem;
    }
  }
  .yes {
    // background-color: var(--white);
    padding: 5em 0em;
    position: relative;
    height: 50vh;

    background: url("../../assets/webp/Story016.webp") top center no-repeat;
    // background-blend-mode: soft-light;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    // The background-attachment fixed is zooming in on the background image on iphones, this is a fix.
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      background-attachment: scroll;
    }
    h2 {
      color: var(--sec-color);
      font-size: 4rem;
    }
  }

  .best,
  .story,
  .propose,
  .yes {
    height: 100vh;

    h2 {
      text-shadow: 4px 2px 1px #000000;
    }
    // @media screen and (max-width: 900px) {
    //   height: 50vh;
    // }
  }
}
