.App {
  text-align: center;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  width: 100%;
  background: rgba(10, 10, 10, 0.7);
  height: fit-content;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
}

:root {
  --maxWidth: 1200px;
  --white: #fff;
  --main-color: #622126;
  --sec-color: #fae6e7;
  /* --sec-color: #d6aa9d; */
  --medGrey: #979797;
  --darkGrey: #323232;
  --black: #000;
  --fontSuperBig: 2.5rem;
  --fontBig: 1.5rem;
  --fontMed: 1.2rem;
  --fontSmall: 0.8rem;
}
*,
*::after,
*::before {
  --webkit-box-sizing: border-box;
  --moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  /* color: var(--white); */
  /* height: auto; */
  /* background-attachment: fixed; */
}

/* ====General fonts styles=== */

h2 {
  font-size: var(--fontSuperBig);
  font-weight: 700;
  /* color: var(--sec-color); */
}
h3 {
  font-size: var(--fontBig);
  font-weight: 500;
  /* color: var(--black); */
}

h4 {
  font-size: var(--fontMed);
  font-weight: 500;
  /* color: var(--black); */
}

p {
  font-size: var(--fontSmall);
  font-weight: 400;
  /* color: var(--black); */
}

a {
  text-decoration: none !important;
}

/* ====General Button styles=== */
.main-btn {
  background-color: var(--main-color);
  box-shadow: 0px 24px 48px rgba(27, 44, 193, 0.1);
  border: none;
  border-radius: 4px;
  padding: 1em 2em;
}

.main-btn a {
  color: #fff;
}

.main-btn:hover {
  transform: scale(1.1);
  /* background-color: var(--sec-color); */
}

/* ===General Image Animation=== */
/* img {
  transition: all 0.3s;
  animation: animateallPic 0.5s;
} */

/* img:hover {
  opacity: 0.8;
} */

/* @keyframes animateallPic {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.title {
  font-family: "Arizonia", cursive;
  font-size: var(--fontSuperBig);
}
